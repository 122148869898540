.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  --main-color: #4437C1;
  --second-color: #373737;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'quasimoda';
  src: url('./assets/fonts/Quasimoda\ Medium.ttf');
}

@font-face {
  font-family: 'pacifico';
  src: url('./assets/fonts/Pacifico.ttf');
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px){
  table:not(.cardItemTable) thead {
    display: none;
  }
  
  table:not(.cardItemTable) tr{
    display: block;
  }
  
  table:not(.cardItemTable) td {
    display: block;
    text-align: right;
    padding: 0 15px !important;
  }
  
  table:not(.cardItemTable) td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}

table:not(.cardItemTable) tr:nth-child(odd){
  background-color: #f5f5f5;
}

table, td, th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}

label {
  display: block;
  margin-top: 10px;
}

.durationZone:hover {
  cursor: inherit;
}

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.buttonLog {
  background-color: var(--main-color);
  border-radius: 20px;
  border: none;
  font-family: 'quasimoda';
  font-size: calc(10px + 0.2vw);
  color: white;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.buttonLog:hover {
  cursor: pointer;
}

.buttonLog:disabled {
  opacity: 0.6;
}

.buttonLog:disabled:hover {
  cursor: default;
}

.buttonWithArrow {
  position: relative;
}

.buttonWithArrow::after {
  content: "";
  position: absolute;
  border-color: white;
  border-style: solid;
  border-width: 2px 2px 0 0;
  display: block;
  height: 8px;
  width: 8px;
  transform: rotate(45deg);
  -webkit-transform: rotate( 45deg );
  top: calc(50% - 2.83px);
  right: 20px;
}

.linkLog {
  font-family: 'quasimoda';
  font-size: calc(10px + 0.2vw);
  color: var(--main-color) !important;
}

.linkLog:hover {
  cursor: pointer;
}

.texteLog {
  font-family: 'quasimoda' !important;
  font-size: calc(10px + 0.3vw);
}

.loader {
  justify-self: center;
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--main-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.priceHeader {
  font-family: 'quasimoda' !important;
  color: var(--main-color);
  justify-self: center;
  text-align: center;
  font-size: calc(18px + 0.6vw);
  position: relative;
  height: calc(20px + 2vw);
  width: 100%;
}

.priceHeader:after {
  position: absolute;
  content: '';
  height: 1px;
  bottom: -30px; 
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--main-color);
}

.priceAmount {
  font-family: 'quasimoda' !important;
  color: var(--second-color);
  justify-self: center;
  text-align: center;
  font-size: calc(30px + 0.8vw);
  width: fit-content;
}

.forLife {
  position: relative;
}

.forLife::after {
  position: absolute;
  content: 'à vie';
  right: -50px;
  bottom: 10px;
  font-size: calc(12px + 0.3vw);
}

.availableFeature {
  font-family: 'quasimoda' !important;
  color: var(--second-color);
  font-size: calc(14px + 0.3vw);
  margin-top: 10px;
  margin-bottom: 10px;
}

.squareItemCalendar {
  z-index: 10;
  text-align: center;
  justify-content: center;
  width: 32%;
  aspect-ratio: 1/1;
  align-content: center;
  display: grid;
  position: relative;
  border: none;
  border-radius: 10px;
  background-color: #b3cfff;
	cursor: pointer;
	box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
}

.titleCardPhone {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px !important;
  font-family: 'quasimoda' !important;
}

.MuiCardHeader-root {
  padding-bottom: 0 !important;
}

.MuiCardHeader-title {
  font-family: 'quasimoda' !important;
  font-weight: 600 !important;
  font-size: calc(16px + 0.4vw) !important;
}

.MuiCardHeader-subheader {
  font-family: 'quasimoda' !important;
}

.pagination {
  justify-content: center;
  margin-top: 10px;
}

.pagination a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: var(--main-color);
  color: white;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

.tooltipButtons {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltipButtons .tooltiptextDuplicate {
  visibility: hidden;
  width: 90vw;
  background-color: black;
  color: #fff;
  padding: 5px 5px 30px 5px;
  border-radius: 6px;
  text-align: left;
  font-family: 'quasimoda';
  font-size: calc(10px + 0.5vw);
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 100%;
  left: -50px;
  z-index: 1;
}

.tooltipButtons .tooltiptextDuplicate::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 70px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltipButtons .tooltiptextPublish {
  visibility: hidden;
  width: 90vw;
  background-color: black;
  color: #fff;
  padding: 5px 5px 30px 5px;
  border-radius: 6px;
  text-align: left;
  font-family: 'quasimoda';
  font-size: calc(10px + 0.5vw);
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 100%;
  left: -100px;
  z-index: 1;
}

.tooltipButtons .tooltiptextPublish::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 120px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltipButtons .tooltiptextDel {
  visibility: hidden;
  width: 90vw;
  background-color: black;
  color: #fff;
  padding: 5px 5px 30px 5px;
  border-radius: 6px;
  text-align: left;
  font-family: 'quasimoda';
  font-size: calc(10px + 0.5vw);
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 100%;
  left: -150px;
  z-index: 1;
}

.tooltipButtons .tooltiptextDel::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 170px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

/* Tooltip text */
.tooltipButtons .tooltiptextCreationScreen {
  visibility: hidden;
  width: 90vw;
  background-color: black;
  color: #fff;
  padding: 5px 5px 30px 5px;
  border-radius: 6px;
  text-align: left;
  font-family: 'quasimoda';
  font-size: calc(10px + 0.5vw);
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 100%;
  left: -50px;
  z-index: 100;
}

.tooltipButtons .tooltiptextCreationScreen::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 20px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltipButtonsCard {
  position: absolute;
  display: inline-block;
}

.tooltipButtonsCard .tooltiptextCard {
  visibility: hidden;
  width: 45vw;
  background-color: black;
  color: #fff;
  padding: 5px 5px 30px 5px;
  border-radius: 6px;
  text-align: left;
  font-family: 'quasimoda';
  font-size: calc(10px + 0.5vw);
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: 1;
}

.tooltipButtonsCard .tooltiptextCard::after {
  content: " ";
  position: absolute;
  top: 18px;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltipButtonsCard .tooltiptextCardOpen {
  visibility: hidden;
  width: 45vw;
  min-height: 110px;
  background-color: black;
  color: #fff;
  padding: 5px 5px 30px 5px;
  border-radius: 6px;
  text-align: left;
  font-family: 'quasimoda';
  font-size: calc(10px + 0.5vw);
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: 1;
}

.tooltipButtonsCard .tooltiptextCardOpen::after {
  content: " ";
  position: absolute;
  top: 80px;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.bottom-nav-item--title {
  font-family: 'quasimoda';
  font-size: 12px !important;
}

.centered {
  display: grid;
  justify-content: center;
  align-items: center;
}

.carouselarrow:hover button {
  background: none !important;
  opacity: 1 !important;
}

.carouselarrow>button {
  background: none !important;
  opacity: 1 !important;
}

.normalMarginTop {
  margin-top: 5vw;
}

.gridComponent {
  display: grid;
}

.informationInput{
  color: grey;
  font-size: 12px;
}

.creationScreen {
  width: 100%;
}

.titleDefault {
  font-family: 'quasimoda';
  font-size: calc(14px + 1vw);
  color: var(--main-color);
}

.mybreadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
}

.mybreadcrumb label:not(:last-child)::after {
  display: inline-block;
  margin: 0 .25rem;
  color: var(--second-color);
  content: "→";
}

.mybreadcrumbitem {
  font-family: "quasimoda";
  font-size: calc(10px + 0.5vw);
  color: var(--main-color);
  text-decoration: underline;
}

.mybreadcrumbitem:hover {
  cursor: pointer;
}

.progressBarWrapper {
  margin-bottom: 15px;
  width: 100%;
}

.progressBarWrapperLarge {
  width: calc(100% - 50px);
}

.clickableLink {
  font-family: 'quasimoda';
  font-size: calc(10px + 0.5vw);
  color: var(--main-color);
  text-align: left;
  position: relative;
  padding-left: 20px;
}

.clickableLink:hover {
  cursor: pointer;
}

.clickableLink::before {
  content: "";
  position: absolute;
  border-color: var(--main-color);
  border-style: solid;
  border-width: 0px 0px 2px 2px;
  display: block;
  height: 8px;
  width: 8px;
  transform: rotate(45deg);
  -webkit-transform: rotate( 45deg );
  top: calc(50% - 2.83px);
  left: 0px;
}

.daysDate {
  font-family: 'quasimoda';
  font-size: calc(10px + 0.2vw);
  color: black;
  font-weight: bold;
  padding: 2px;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
}

.dayIsSet {
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(-180deg);
  border-radius: 0 0 0 10px;
  background-color: #3CB371;
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
}

.dayIsNotSet {
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(-180deg);
  border-radius: 0 0 0 10px;
  background-color: #B22222;
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
}

.chevronLeft {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 5;
}

.chevronRight {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 5;
}

.headerHomePage {
  position: relative;
  padding-left: 3vw;
}

.headerHomePage::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 80%;
  top: 10%;
  background-color: #7FB285;
}

.homePrez {
  font-family: 'Khmer';
  font-style: normal;
  font-size: calc(1vw + 15px);
}

.cardTitle {
  font-family: 'Khmer';
  font-style: normal;
  font-size: calc(0.5vw + 12px);
  color: #EEE9DA;
  padding: 0;
  margin: 0;
  justify-self: self-start;
  align-self: center;
}

.cardImg {
  justify-self: end;
  align-self: center;
  height: calc(1vw + 30px);
}

.cardContent {
  font-family: 'Khmer';
  font-style: normal;
  font-size: calc(0.5vw + 10px);
  color: #EEE9DA;
  padding: 0;
  margin-top: 15px;
}

.startButton {
  background: #7FB285;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  width: 400px;
  height: 80px;
  color: #EEE9DA;
  justify-self: center;
  position: relative;
}

.startButton::after {
  content: "→";
  position: absolute;
  right: 20px;
}

.startButton:hover {
  cursor: pointer;
}

.startButtonMobile {
  background: #7FB285;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  width: 80%;
  height: 40px;
  color: #EEE9DA;
  justify-self: center;
  position: relative;
}

.startButtonMobile::after {
  content: "→";
  position: absolute;
  right: 20px;
}

.startButtonMobile:hover {
  cursor: pointer;
}

.txtGreen {
  font-family: 'Khmer';
  font-style: normal;
  font-size: calc(1vw + 15px);
  color: #7FB285;
}

.cardScenario {
  background: #285238;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 15px;
  display: grid;
}

.linkToStart {
  font-family: 'Khmer';
  font-style: italic;
  text-decoration: underline;
  font-size: calc(0.5vw + 10px);
  color: #EEE9DA;
  padding: 0;
  margin-top: 15px;
  width: fit-content;
  justify-self: flex-end;
}

.linkToStart:hover {
  cursor: pointer;
}

.logbutton {
  border-radius: calc(5px + 0.5vw);
  font-size: calc(5px + 0.5vw);
  padding: calc(5px + 0.5vw) calc(10px + 1vw);
  border: 3px solid var(--main-color);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 10px;
}

.logbutton:hover {
  cursor: pointer;
}

.toolbar {
  position: fixed;
  top: 0;
  overflow: hidden;
  height: fit-content;
  max-height: calc(60px + 3vw);
  width: 100vw;
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: calc(5px + 1vw);
  padding-bottom: calc(5px + 1vw);
  z-index: 100;
}

.logbuttons {
  display: grid;
  padding-right: calc(5px + 2vw);
  align-items: center;
  justify-self: flex-end;
}

.loginButton {
  background-color: #ffffff;
  color: black;
  font-family: 'Quasimoda';
}

.newaccountButton {
  background-color: var(--main-color);
  color: #ffffff;
}

.toolbarLinkLog {
  color: var(--main-color);
  display: grid;
  justify-items: flex-end;
}

.toolbarLinkLog:hover {
  text-decoration: none;
  color: var(--main-color);
}

.toolbarLinkReg {
  color: #ffffff;
}

.toolbarLinkReg:hover {
  text-decoration: none;
  color: #ffffff;
}

.mainBody {
  margin-top: calc(20px + 2vw);
  margin-bottom: 30px;
  display: grid;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
}

.prezPart {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.prezHome {
  padding-left: calc(30px + 2vw);
  padding-right: calc(30px + 2vw);
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.taglineHome {
  font-family: 'pacifico';
  font-size: calc(22px + 0.8vw);
  text-align: left;
  color: #000000;
}

.descHome {
  font-family: 'quasimoda';
  font-size: calc(14px + 0.4vw);
  color: var(--second-color);
}

.tryButton {
  width: fit-content;
  margin-top: calc(10px + 2vw);
  padding-left: calc(15px + 1.5vw);
  padding-right: calc(15px + 1.5vw);
  font-size: calc(14px + 0.4vw);
  justify-self: flex-start;
}

.tryButton:hover {
  box-shadow: 2px 2px #000000;
}

.demoHome {
  font-family: 'pacifico';
  font-size: calc(22px + 0.8vw);
  color: #000000;
  text-align: center;
}

.cursorPointer:hover {
  cursor: pointer;
}

.footer {
  background-color: #000000;
  min-height: calc(100px + 2vw);
  padding-top: calc(10px + 2vw);
  padding-bottom: calc(10px + 2vw);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  align-self: flex-end;
}

.footerPhone {
  background-color: #000000;
  padding-top: calc(10px + 2vw);
  padding-bottom: calc(10px + 2vw);
  display: grid;
  justify-items: center;
  align-items: center;
  align-self: flex-end;
}

.footerLabels > label {
  font-family: 'quasimoda';
  font-size: calc(12px + 0.6vw);
  color: #ffffff;
  margin-bottom: 1vw;
}

.footerLabels > label:hover{
  cursor: pointer;
}

.footerLabels > a {
  font-family: 'quasimoda';
  font-size: calc(12px + 0.6vw);
  color: #ffffff !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.footerLabels > a:hover{
  cursor: pointer;
}


.titleBurgerMenu {
  color: var(--second-color) !important;
  padding-left: 0 !important;
}

/* Burger menu */
/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 22px;
  margin-right: 2vw;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 10px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 1px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: fit-content;
  top: 0px;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  border-left: 1px solid var(--second-color);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: var(--main-color);
  font-family: 'quasimoda';
  padding-left: 15px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  height: fit-content !important;
}

.disableLinkCss:hover {
  color: inherit;
  text-decoration: none;
}